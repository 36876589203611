window.FastClick = require('fastclick')
require('lazyload')

require('./console')
require('./cursor-effects')
require('./image-box')
require('./image-title')
require('./pjax')
require('./tag')
require('./typography')
require('./custom')
